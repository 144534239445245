import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import faqStoreModule from '../faqStoreModule';

export default function useFaqList() {
  const APARTMENT_STORE_MODULE_NAME = "faq";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, faqStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    title: '',
    status: true,
    order: 1,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      width: '100px',
      tdClass: 'text-center',
      sortable: false,
    },
    {
      label: '#',
      field: 'order',
      tdClass: 'text-center',
      thClass: 'text-center',
    },
    {
      label: 'Nội dung câu hỏi',
      field: 'title',
    },
    {
      label: 'Hiển thị?',
      field: 'status',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const province = ref(null);
  const department = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    start: 0,
    length: 10,
    status: "-1",
    draw: "1",
    specialist: "-1",
    subSpecialist: "-1",
  });

  //   API Call

  const fetchFaqs = () => {
    store
      .dispatch('faq/fetchFaqs', serverParams.value)
      .then(response => {
        const { data, recordsTotal } = response.data;
        totalRecords.value = recordsTotal;
        rows.value = data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchFaqs();
  };
  const importData = file => {
    const formData = new FormData();
    formData.append('file', file);
    store
      .dispatch('apartment/importApartments', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('apartment/exportApartments', serverParams.value)
      .then(response => {
        console.log(response.data);
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteFaq = id => {
    store
      .dispatch('faq/deleteFaq', id)
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ start: (params.currentPage - 1) * serverParams.value.length });
  };

  const onPerPageChange = params => {
    updateParams({ length: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : "-1" });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val) return { variant: 'primary', title: "Hiển thị", icon: 'SmileIcon' };
    return { variant: 'danger', title: "Không hiển thị", icon: 'MehIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  return {
    analytics,
    item,
    columns,
    rows,
    province,
    department,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    deleteFaq,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    resolveStatusVariant,
    resolveFamilyDoctorVariant,
  };
}
